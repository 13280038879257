import React, { useEffect, useState } from 'react';
import Swiper from 'swiper';
import { Autoplay, Parallax, Navigation, Pagination } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import '../styles/carousel.scss';

interface CarouselItem {
  path: string;  
}

interface CarouselComponentProps {
  carouselItems: CarouselItem[]; 
}

const CarouselComponent: React.FC<CarouselComponentProps> = ({ carouselItems }) => { 
  const [loadedItems, setLoadedItems] = useState<CarouselItem[]>([]);
  const [isFirstItem, setIsFirstItem] = useState<boolean>(false);
  const [isLastItem, setIsLastIem] = useState<boolean>(false);

  useEffect(() => {
    // Cuando el idioma cambia, actualiza el carrusel
    if (carouselItems && carouselItems.length > 0) {
      setLoadedItems(carouselItems);
    }
  }, [carouselItems]);

  useEffect(() => {
    if (loadedItems.length > 0) {
      const swiper = new Swiper('.swiper', {
        modules: [Autoplay, Parallax, Navigation, Pagination],
        slidesPerView: 3, 
        centeredSlides: false,
        centeredSlidesBounds: false,
        centerInsufficientSlides: false,        
        rewind: true,
        speed: 1000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          100: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
      });
      swiper.on('reachBeginning', function(){ 
        setIsFirstItem(true)
      });
      swiper.on('reachEnd', function(){ 
        setIsLastIem(true)
      });
    } 
  }, [loadedItems]); // Asegúrate de que el carrusel se inicializa después de que los items estén cargados

  if (!loadedItems || loadedItems.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="swiper mx-auto relative">
      <div className="swiper-wrapper flex justify-stretch place-items-stretch">
        {loadedItems.map(({ path }, index) => (
          <div key={index} className="swiper-slide flex h-full shadow-item">
            <img src={'/statics/'+path} alt="" />
          </div>
        ))}
      </div>

      <div className="w-full flex flex-row items-center justify-center pt-6 pb-2 relative">
        <button disabled={isFirstItem} className="swiper-button-prev relative my-auto z-10 min-w-8 min-h-8 flex items-center justify-center shadow-md bg-white rounded-full text-[#ff4848]">❮</button>
        <div className="swiper-pagination w-10 h-5 flex justify-center items-center mx-5"></div>
        <button disabled={isLastItem} className="swiper-button-next relative my-auto z-10 min-w-8 min-h-8 flex items-center justify-center shadow-md bg-white rounded-full text-[#ff4848]">❯</button>
      </div>
    </div>
  );
};

export default CarouselComponent;